.e5cb-sys-reboot {
  height: 100px;
  padding-right: 20px;
}

.e5cb-sys-reboot > div > div > div.e5angular-gauge > div {
  align-items: center;
}

.e5cb-sys-reboot > div:first-child > div > div,
.e5cb-sys-reboot > div:nth-child(2) > div > div {
  height: 430px;
}

.e5cb-sys-reboot .e5pie-chart {
  height: 400px;
}

.reboot-reason-list {
  ul {
    padding: 0;
  }

	li {
		cursor: pointer;
		list-style-type: none;
    margin-bottom: 5px;
	}

  .active {
    font-weight: bold;
  }
}
