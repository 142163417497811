/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.e5mainframe {
  font-size: 1em;
  background-color: #222221;
  color: #FFFFFF;
  padding: 2px;
  height: 100%;
  overflow: hidden;
}

.e5mainframe .mainpage {
  overflow: auto;
}

@media print {
  .e5mainframe {
    overflow: auto;
    height: auto;
  }
  .section.wifisection {
    margin-top: 360px;
  }
  .section.syscpusection {
    margin-top: 65px;
  }
  .section.sysmemsection {
    margin-top: 45px;
  }
  .section.sysrebsection {
    margin-top: 100px;
  }
  .section.sysrebsection2 {
    margin-top: 135px;
  }
  .section.systempsection {
    margin-top: 95px;
  }
  .section.sysflashsection {
    margin-top: 60px;
  }
  .section.sysflashsection2 {
    margin-top: 130px;
  }
}

.e5overlaycontent {
  width: 100%;
  height: 100%;
  background-color: #F4F4F47F;
}

.e5page {
  background-color: #F9FCFF;
  color: #000000;
  padding: 0 20px 0 20px;
  overflow: auto;
}

.e5testpage {
  background-color: #FFFFFF;
  color: #000000;
  padding: 5px;
}

.e5compo, .e5-bg-white {
  background: #FFFFFF;
  border-radius: 10px;
  color: #000000;
  padding: 5px;
}

.e5compo > div {
  text-align: center;
}

.e5marginbottom-15 {
  margin-bottom: 15px;
}

.e5marginbottom-10 {
  margin-bottom: 10px;
}

.e5nomarginbottom {
  margin-bottom: 0 !important;
}

.e5cursorpointer {
  cursor: pointer;
}

.e5flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.e5flexcenterstart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.e5section {
  background: #FFFFFF;
  box-shadow: 0px 6px 12px 0px #B8B8B814;
  border-radius: 10px;
}

.e5nomargin {
  margin: 0;
}

.e5sectionpadding {
  padding: 20px 24px 20px 24px;
}

.e5textweight600 {
  font-weight: 600;
}

.e5nomargintop {
  margin-top: 0;
}

.e5borderbottom {
  border-bottom: 1px solid #E7EAEE;
}

.e5text {
  font-size: 14px;
  font-weight: 500;
}

.e5textcolor {
  color: #767675;
}

.e5xpadding-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.e5ypadding-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.e5marginright-8 {
  margin-right: 8px;
}

.e5formgroupdisabled {
  background-color: #F2F7FC;
  border-radius: 10px;

  width: 563px;

  margin-bottom: 16px;
  padding: 6px 16px 10px 16px;
}

.e5formgroupdisabled label {
  font-size: 14px;
  font-weight: 500;
  color: #0080FF;  
  margin-bottom: 10px;
}


.e5formgroupdisabled input {
  background-color: #F2F7FC;
  font-weight: 600;
}

.e5formgroup {
  border: 1px solid #E7EAEE;
  border-radius: 10px;

  width: 563px;

  margin-bottom: 16px;
  padding: 6px 16px 10px 16px;
}

.e5formgroup label {
  font-size: 14px;
  font-weight: 500;
  color: #767675;  
  margin-bottom: 10px;
}

.e5formgroup input,
.e5formgroupdisabled input {
  border: none;
  font-family: Montserrat;
  font-size: 14px;
  padding: 0;
}

.e5formgroup input::placeholder{
  color: #BECAD5;
}

.e5button {
  background: #0080FF;
  color: white;
  font-size: 14px;
  padding: 15px;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  font-family: Montserrat;
}

.e5button:hover {
  background: #0058A6;
}

.e5button:disabled {
  background: #BECAD5;
  cursor: not-allowed;
}

.e5justifycontentbewteen {
  justify-content: space-between;
}

.e5twocolumnsflex {
  flex: 1 0;
}

.e5compo .e5compotitle {
  color: black;
  font-size: 16px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.e5compo .e5texttitle {
  color: black;
  font-size: 16px;
  font-weight: 700;
}

.e5compo .e5iconinfo {
  margin-left: 10px;
}

.e5hloading {
  align-items: center;
  justify-content: center;
  margin: 20px 0 20px 0;
}

.e5formemptyfield input {
  background-color: #FFCCCC;
}

.e5statusmessage {
  color: #FF0000;
  font-size: 0.8em;
}

.e5detailtable td {
  padding: 0 10px 0 3px;
  vertical-align: top;
}

.e5detailtable td.label {
  color: #505050;
  text-align: right;
  min-width: 130px;
}

.e5spinwait {
  display: inline-block;
  margin-left: 20px;
}

.e5line-0 {
  display: flex;
  flex-direction: row;
}

.e5line-0 > * {
  margin: 0 0px 0 0;
}

.e5line-0 > *:last-child {
  margin: 0;
}

.e5line-0 > .e5linefull {
  flex: 1;
}

.e5line-0 > .e5linefull2 {
  flex: 2;
}

.e5column-0 {
  display: flex;
  flex-direction: column;
}

.e5column-0 > * {
  margin: 0 0 0px 0;
}

.e5column-0 > *:last-child {
  margin: 0;
}

.e5column-0 > .e5columnfull {
  flex: 1;
}

.e5line-2 {
  display: flex;
  flex-direction: row;
}

.e5line-2 > * {
  margin: 0 2px 0 0;
}

.e5line-2 > *:last-child {
  margin: 0;
}

.e5line-2 > .e5linefull {
  flex: 1;
}

.e5line-2 > .e5linefull2 {
  flex: 2;
}

.e5column-2 {
  display: flex;
  flex-direction: column;
}

.e5column-2 > * {
  margin: 0 0 2px 0;
}

.e5column-2 > *:last-child {
  margin: 0;
}

.e5column-2 > .e5columnfull {
  flex: 1;
}

.e5line-5 {
  display: flex;
  flex-direction: row;
}

.e5line-5 > * {
  margin: 0 5px 0 0;
}

.e5line-5 > *:last-child {
  margin: 0;
}

.e5line-5 > .e5linefull {
  flex: 1;
}

.e5line-5 > .e5linefull2 {
  flex: 2;
}

.e5column-5 {
  display: flex;
  flex-direction: column;
}

.e5column-5 > * {
  margin: 0 0 5px 0;
}

.e5column-5 > *:last-child {
  margin: 0;
}

.e5column-5 > .e5columnfull {
  flex: 1;
}

.e5line-10 {
  display: flex;
  flex-direction: row;
}

.e5line-10 > * {
  margin: 0 10px 0 0;
}

.e5line-10 > *:last-child {
  margin: 0;
}

.e5line-10 > .e5linefull {
  flex: 1;
}

.e5line-10 > .e5linefull2 {
  flex: 2;
}

.e5column-10 {
  display: flex;
  flex-direction: column;
}

.e5column-10 > * {
  margin: 0 0 10px 0;
}

.e5column-10 > *:last-child {
  margin: 0;
}

.e5column-10 > .e5columnfull {
  flex: 1;
}

.e5line-20 {
  display: flex;
  flex-direction: row;
}

.e5line-20 > * {
  margin: 0 20px 0 0;
}

.e5line-20 > *:last-child {
  margin: 0;
}

.e5line-20 > .e5linefull {
  flex: 1;
}

.e5line-20 > .e5linefull2 {
  flex: 2;
}

.e5line-20 > .e5linefull6 {
  flex: 6;
}

.e5column-20 {
  display: flex;
  flex-direction: column;
}

.e5column-20 > * {
  margin: 0 0 20px 0;
}

.e5column-20 > *:last-child {
  margin: 0;
}

.e5column-20 > .e5columnfull {
  flex: 1;
}

.e5fullheight {
  height: 100%;
}

.e5grid-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.p-2 {
  padding: .5rem;
}

.h-100{
  height: 100%;
}

.e5-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.e5-main-content-box {
  background: #FFFFFF;
  padding: 16px 32px 24px 32px;
  border-radius: 10px 0px 0px 0px;
  box-shadow: 0px 6px 12px 0px #B8B8B814;
}

.e5-main-content-box .e5-inside-box {
  border: 1px solid #E7EAEE;
  border-radius: 10px;
  padding: 24px;
}

.e5-main-content-box .e5-inside-box-top-rounded {
  border: 1px solid #E7EAEE;
  border-radius: 10px 10px 0px 0px;
  padding: 24px;
}

.e5-main-content-box .e5-inside-box-bottom-rounded {
  border: 1px solid #E7EAEE;
  border-radius: 0px 0px 10px 10px;
  padding: 24px;
}

.e5-main-content-box .e5-inside-box-top-rounded + .e5-inside-box-bottom-rounded {
  border-top: none;
}

.e5-text-color {
  color: #767675;
}

.e5-bold-text-color {
  color: #222221;
  font-weight: 600;
}

.e5-up-text {
  color: #214F0B;
  background: #D5E3A9;
  font-weight: 600;
  padding: 2px 10px 2px 10px;
  border-radius: 5px;

  width: min-content;
}

.e5-underline-text {
  text-decoration: underline;
}

.e5-chart-title {
  color: '#222221';
  font-size: 18px;
  font-weight: 700;
}

.e5-off-text {
  color: #B01402;
  background: #F9E1E2;
  font-weight: 600;
  padding: 2px 10px 2px 10px;
  border-radius: 5px;

  width: min-content;
}

.e5-disabled-text {
  color: #676767;
  background: #F0F0F0;
  font-weight: 600;
  padding: 2px 10px 2px 10px;
  border-radius: 5px;

  width: min-content;
}

.w-100 {
  width: 100%;
}

.mt-5 {
  margin-top: 2rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.e5-grid-two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.e5-primary-button {
  background: #0080FF;
  color: white;
  font-size: 14px;
  padding: 15px;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  font-family: Montserrat;
}